<template>
  <ul class="c-share_options" data-title="Share">
    <li>
      <ShareNetwork
              network="facebook"
              :url="events_data.href"
              :title="events_data.title"
              :description="events_data.description"
      >
        Facebook
      </ShareNetwork>
    </li>
    <li>
      <ShareNetwork
              network="twitter"
              :url="events_data.href"
              :title="events_data.title"
              :description="events_data.description"
      >
        Twitter
      </ShareNetwork>
    </li>
    <li>
      <ShareNetwork
              network="linkedin"
              :url="events_data.href"
              :title="events_data.title"
              :description="events_data.description"
      >
        Linkedin
      </ShareNetwork>
    </li>
  </ul>
</template>

<script>
  export default {
    props: ['events'],
    data() {
      return {
        events_data: []
      }
    },
    created() {
      this.events_data = this.events;
      this.events_data.href = window.location.origin + '/' + 'event/' + this.events_data.slug;
    },
    name: "ShareButtons"
  }
</script>

<style scoped>

</style>
