<template>
  <section id="events" class="ptb-100 pt-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <header class="events-header">
            <div class="buttons-wrapper">
              <div class="d-flex position-relative">
                <div class="buttons-hidden" v-if="events_isValidating"></div>
                <button class="header-btn prev" @click.prevent="goPrevPage()" :disabled="events_data && +offset === 0">
                  <img src="../../assets/img/left-arrow.png" alt="">
                </button>
                <button class="header-btn next" @click.prevent="goNextPage()" :disabled="events_data && +events_data.data.length < this.limit">
                  <img src="../../assets/img/left-arrow.png" alt="">
                </button>
              </div>

              <button class="header-btn today" @click="goToday">
                Today
              </button>

              <v-date-picker v-model="dateInput" :update-on-input="false" @input="updateDate" color="gray">
                <template v-slot="{ inputValue, showPopover }">
                  <h1 @click="showPopover">
                    <span class="desktop">{{momentForDate(dateInput)}}</span>
                    <img src="../../assets/img/left-arrow.png" class="arrow-down" alt="">
                  </h1>
                </template>
              </v-date-picker>
            </div>
          </header>

          <div class="tribe-events-calendar-list__month-separator"></div>

          <div class="events-posts">
            <div class="loading" v-if="events_data === undefined && !events_error">
              <Loading />
            </div>
            <div v-if="events_data">
              <div class="not-found" v-if="events_data.data.length === 0">
                <i class="fa fa-calendar-times-o"></i>
                There were no results found.
              </div>
              <div class="row post-row" v-else v-for="events in events_data.data" :key="events.id">
                <div class="col-md-1 date-section">
                  <div class="time validating" :class="{'isValidating': events_isValidating}">
                    <span>{{momentMonth(events.start_date)}}</span>
                    <strong>{{momentDay(events.start_date)}}</strong>
                  </div>

                  <div class="c-share validating" :class="{'isValidating': events_isValidating}">
                    <input class="c-share__input" type="checkbox" :id="'checkbox'+events.id">
                    <label class="c-share__toggler" :for="'checkbox'+events.id">
                      <span class="c-share__icon"></span>
                    </label>

                    <ShareButtons :events="events" />
                  </div>
                </div>

                <div class="col-md-11">
                  <div class="row">
                    <div class="col-md-7 post-body d-flex flex-column align-items-start">
                      <div class="event-time validating" :class="{'isValidating': events_isValidating}">
                          <span v-if="events.start_date">
                            {{momentMonth(events.start_date)}} {{momentDay(events.start_date)}}, {{momentYear(events.start_date)}} {{events.start_time ? '@ ' + events.start_time : ''}}
                          </span>
                        <span v-if="events.end_date && events.start_date !== events.end_date">
                            - {{momentMonth(events.end_date)}} {{momentDay(events.end_date)}}, {{momentYear(events.end_date)}} {{events.end_time ? '@ ' +events.end_time : ''}}
                          </span>
                      </div>
                      <div class="location-section validating" v-if="events.location" :class="{'isValidating': events_isValidating}">
                        <i class="fa fa-crosshairs"></i>
                        {{events.location}}
                      </div>
                      <router-link :class="{'isValidating': events_isValidating}" class="title validating" :to="{ name: 'event', params: params({url: events.slug}) }">
                        {{events.title}}
                      </router-link>
                      <p v-if="events.description" class="validating" :class="{'isValidating validating': events_isValidating}">{{trimMyString(events.description, 250)}}</p>

                      <router-link :to="{ name: 'event', params: params({url: events.slug}) }" class="post-button validating" :class="{'isValidating validating': events_isValidating}">
                        <i class="flaticon-curve-arrow-1"></i> Learn more
                      </router-link>
                    </div>
                    <div class="col-md-5">
                      <router-link
                        :to="{ name: 'event', params: params({url: events.slug}) }"
                        :class="{'isValidating validating': events_isValidating}"
                        class="post-img validating d-block"
                      >
                        <DefaultImage :id="events.image" :height="'250px'" :width="'100%'" />
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tribe-events-calendar-list__month-separator"></div>

          <div class="footer-buttons position-relative">
            <div class="buttons-hidden" v-if="events_isValidating"></div>
            <button class="previous" @click.prevent="changeType()" :disabled="type === 'lt'">
              <i class="flaticon-curve-arrow"></i>
              Past Events
            </button>
            <button class="next" @click.prevent="goNextPage()" :disabled="events_data && +events_data.data.length < this.limit">
              More Events
              <i class="flaticon-curve-arrow-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import DefaultImage from "../../components/DefaultImage";
  import moment from "moment";
  import Loading from "../../components/Loading";
  import DatePicker from 'v-calendar/lib/components/date-picker.umd';
  import useSWRV from "swrv";
  import api from "../../services/api";
  import ShareButtons from "./ShareButtons";

  export default {
    name: "EventsReview",
    props: ['offset', 'limit', 'date', 'type'],
    data() {
      return {
        dateInput: new Date(),
      }
    },
    setup(props) {
      const {
        data: events_data,
        error: events_error,
        isValidating: events_isValidating
      } = useSWRV(() => `items/events?status=published&${props.type === 'lt' ? `filter[start_date][lt]=${moment().format('YYYY-MM-DD')}&sort=start_date` : `filter[end_date][gte]=${props.date}&sort=end_date` }&offset=${props.offset}&limit=${props.limit}`, api.fetcher);

      return {
        events_data,
        events_error,
        events_isValidating
      };
    },
    components: {
      ShareButtons,
      Loading,
      DefaultImage,
      'v-date-picker': DatePicker
    },
    created() {
      this.dateInput = this.date ? this.date : new Date();
    },
    methods: {
      goToday() {
        this.$emit('changeType', 'gte');
        this.$router.replace({name: "events", query: {offset: 0}})
        this.dateInput = moment(new Date()).format('YYYY-MM-DD');
        if (this.$route.query.date !== moment().format('YYYY-MM-DD') || this.$route.query.offset !== this.offset) {
          this.$router.replace({name: "events", query: {date: this.dateInput, offset: 0}})
        }
      },
      goPrevPage() {
        if(this.offset !==0 ) {
          this.$emit('goPrevPage');
        }
        document.getElementById('events-header').scrollIntoView({behavior: "smooth", block: "start"});
      },
      goNextPage() {
        this.$emit('goNextPage');
        document.getElementById('events-header').scrollIntoView({behavior: "smooth", block: "start"});
      },
      updateDate() {
        this.$emit('updateDate', this.dateInput);
      },
      changeType() {
        this.$emit('changeType', 'lt');
        this.$router.replace({name: "events", query: {offset: 0}})
        document.getElementById('events-header').scrollIntoView({behavior: "smooth", block: "start"});
      },
      trimMyString(string, maxLength, start = 0) {
        if (string.length > maxLength) {
          let trimmedString = string.substr(start, maxLength)
          return (
              trimmedString.substr(
                  start,
                  Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
              ) + ' ...'
          )
        }
        return string
      },
      momentDay(date) {
        return moment(date).format('D');
      },
      momentForDate(date) {
        return moment(date).format('MMMM, D YYYY');
      },
      momentMonth(date) {
        return moment(date).format('MMM');
      },
      momentYear(date) {
        return moment(date).format('YYYY');
      },
      params(newParams) {
        return {
          ...this.$route.params,
          ...newParams
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  #events {
    box-shadow: 0 0 14px 14px #0000003b;
    position: relative;
    z-index: 1;
  }

  .validating {
    position: relative;

    &.isValidating:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-image: url("../../assets/img/placeholder.gif");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    &.c-share:after {
      border-radius: 50%;
      z-index: 2;
    }
  }
  .loading {
    height: 400px;
  }
  .not-found {
    align-items: center;
    display: flex;
    color: #141827;
    width: 100%;
    justify-content: center;
    padding: 16px;
    background-color: rgba(20,24,39,.07);
    border-radius: 4px;

    i {
      color: #FC4040;
      font-size: 20px;
      margin-top: -5px;
      margin-right: 10px;
    }
  }
  .buttons-hidden {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.62);
    z-index: 1;
  }
  .post-img .default-img {
    transition: 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
  .post-button {
    text-transform: uppercase;

    &:hover {
      letter-spacing: 1.5px;
      color: #FC4040;
    }
  }
  .events-header {
    margin-bottom: 32px;

    .buttons-wrapper {
      display: flex;
      flex-wrap: wrap;

      h1 {
        font-size: 28px;
        line-height: 1.42;
        font-weight: 500;
        margin: 0;
        cursor: pointer;

        span {
          user-select: none;
        }

        .arrow-down {
          width: 14px;
          transform: rotate(-90deg);
          margin-top: -4px;
          margin-left: 5px;
        }
      }

      button.header-btn {
        display: block;
        background-color: transparent;
        border: none;

        &[disabled="disabled"] {
          cursor: not-allowed;
          opacity: 0.3;
        }

        img {
          width: 18px;
          height: 18px;
          opacity: 0.6;
          transition: .3s;
        }

        &.next {
          img {
            transform: rotate(180deg);
          }
        }

        &:hover {
          img {
            opacity: 1;
          }
        }

        &.today {
          display: inline-block;
          position: relative;
          padding: 5px 25px;
          background-color: #8075f2;
          color: #ffffff;
          text-transform: uppercase;
          font-size: 15px;
          margin-left: 15px;
          margin-right: 25px;
        }
      }
    }
  }

  .footer-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;

    button {
      background-color: transparent;
      border: none;
      font-size: 18px;
      color: #373737;
      transition: 0.5s;

      &:first-child i {
        margin-right: 5px;
      }
      &:last-child i {
        margin-left: 5px;
      }

      &:hover {
        color: #33c0d8;
      }

      &[disabled="disabled"] {
        cursor: not-allowed;
        opacity: 0.3;

        &:hover {
          color: #373737;
        }
      }
    }
  }

  .tribe-events-calendar-list__month-separator {
    display: flex;
    align-items: center;

    &::after {
      background-color: #d5d5d5;
      content: "";
      display: block;
      flex: auto;
      height: 1px;
    }

    .time {
      font-size: 16px;
      line-height: 1.62;
      color: #141827;
    }
  }

  .events-posts {
    margin-top: 32px;

    .row {
      margin-bottom: 25px;
    }

    .time {
      display: flex;
      flex-direction: column;
      text-align: center;
      background-image: url("../../assets/img/white-bg.jpg");
      background-size: cover;
      padding: 10px 0;
      width: 55px;

      span {
        font-family: Helvetica Neue,Helvetica,-apple-system,BlinkMacSystemFont,Roboto,Arial,sans-serif;
        font-size: 11px;
        font-weight: 400;
        line-height: 1.5;
        color: #5d5d5d;
        text-transform: uppercase;
      }

      strong {
        font-size: 24px;
        line-height: 1;
        color: #141827;
        font-weight: 700;
      }
    }

    .event-time {
      margin: 0 0 10px;
      font-size: 14px;
      line-height: 1.62;
      color: #141827;
    }

    .location-section {
      margin: 0 0 10px;
      font-size: 14px;
      line-height: 1.62;
      color: #474747;

      i {
        margin-right: 2px;
      }
    }

    .title {
      font-size: 24px;
      line-height: 1.42;
      margin: 0 0 16px;
      display: inline-block;
      color: #31bfd7;

      &:hover {
        color: #1890a2;
      }
    }
  }
  @media (max-width: 767px) {
    .events-header .buttons-wrapper button.header-btn.today {
      font-size: 13px;
      padding: 3px 18px;
    }
    .post-row {
      border-bottom: 1px solid #d5d5d5;
    }
    .events-header .buttons-wrapper h1 {
      font-size: 20px;
    }
    .events-posts .event-time {
      font-size: 12px;
    }
    .events-posts .title {
      font-size: 21px;
    }
    .footer-buttons button {
      font-size: 15px;
    }
    .date-section {
      display: flex;
      align-items: center;

      .c-share {
        margin: 0;
      }

      .c-share__toggler, .c-share_options {
        left: initial;
        right: 0;
      }

      .c-share__input:checked ~ .c-share_options {
        padding: 1.0625em 55px 1.0625em 1.25em;
      }
    }
    .post-button {
      margin-bottom: 10px;
    }
    .events-posts {
      .time {
        flex-direction: inherit;
        align-items: center;
        background-size: 100px;
        padding: 10px 15px;
        margin-bottom: 5px;
        width: 100%;

        span {
          font-size: 20px;
        }
      }
    }
  }

  .c-share {
    position: relative;
    width: 3.4375em;
    height: 3.4375em;
    margin-top: 15px;
  }

  .c-share__input {
    display: none;
  }
  .c-share__input:checked ~ .c-share__toggler .c-share__icon {
    transition: 0s;
    width: 0;
    height: 0;
    color: #3b3c3f;
  }
  .c-share__input:checked ~ .c-share__toggler .c-share__icon::before {
    transform: rotate(-45deg);
  }
  .c-share__input:checked ~ .c-share__toggler .c-share__icon::after {
    transform: rotate(45deg);
  }
  .c-share__input:checked ~ .c-share_options {
    width: 11.0625em;
    height: 10.5em;
    border-radius: 0.3125em;
    box-shadow: 0 5px 6px -3px rgba(0, 0, 0, 0.26);
    padding: 1.0625em 1.25em 1.0625em 55px;
  }
  .c-share__input:checked ~ .c-share_options::before,
  .c-share__input:checked ~ .c-share_options li {
    transition: 0.3s 0.15s;
    opacity: 1;
    transform: translateY(0);
  }

  .c-share__toggler,
  .c-share_options {
    position: absolute;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    background-color: #fff;
  }

  .c-share__toggler {
    cursor: pointer;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }

  .c-share__icon {
    position: relative;
    left: -0.3125em;
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    color: #3b3c3f;
    background-color: currentColor;
    box-shadow: 0.625em -0.625em, 0.625em 0.625em;
    transition: 0.1s 0.05s;
  }
  .c-share__icon::before, .c-share__icon::after {
    content: "";
    position: absolute;
    width: 1em;
    height: 0.125em;
    background-color: currentColor;
    transition: 0.1s;
  }
  .c-share__icon::before {
    top: 0.1875em;
    left: 0.1875em;
    transform-origin: bottom left;
    transform: rotate(45deg);
  }
  .c-share__icon::after {
    top: -0.125em;
    left: 0.03125em;
    transform-origin: center;
    transform: rotate(-45deg);
  }

  .c-share_options {
    list-style: none;
    margin: 0;
    padding: 1.0625em 1.25em;
    box-sizing: border-box;
    overflow: hidden;
    transition: 0.2s;
    z-index: 1;
  }
  .c-share_options::before {
    content: attr(data-title);
    display: block;
    margin-bottom: 1.25em;
    font-weight: 700;
    color: #FC4040;
  }
  .c-share_options li {
    font-size: 0.875em;
    color: #464646;
    cursor: pointer;
  }
  .c-share_options li:not(:last-child) {
    margin-bottom: 0.75em;
  }
  .c-share_options::before,
  .c-share_options li {
    opacity: 0;
    transform: translateY(0.625em);
    transition: 0s;
  }
  .mobile {
    display: none;
  }
  .desktop {
    display: inline-block;
  }
  @media (max-width: 600px) {
    .mobile {
      display: inline-block;
    }
    .desktop {
      display: none;
    }
  }
</style>
