<template>
  <div data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
    <div class="page-title-area bg-6 event-page-header" >
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="page-title-content text-white" id="events-header">
              <h3>"Every PeaceJam event I do is magical and life-changing - I think it is the love and hope that we generate together!"</h3>
              <h4><i>- Nobel Peace Laureate Betty Williams</i></h4>
              <a class="events-btn-one" @click="$modal.show('bio')"><i class="flaticon-curve-arrow-1"></i> Learn more </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <EventsReview
      :offset="offset"
      :limit="limit"
      :date="date"
      :type="type"
      @goPrevPage="prevPage"
      @goNextPage="nextPage"
      @updateDate="updateDate"
      @changeType="changeType"
    />

    <modal name="bio" height="auto" width="600px">
      <div class="modal-wrapper">
        <div class="close-btn" @click="$modal.hide('bio')"><i class="fa fa-close"></i></div>
        <div class="modal-body">
          <div class="bio-name-wrapper mb-30">
            <div class="section-title position-relative">
              <div class="section-text">
                <h3>PeaceJam Events</h3>
              </div>
            </div>
          </div>
          <div class="description">
              <p>
                For over 25 years, PeaceJam has hosted transformative youth leadership events around the world that bring together aspiring young leaders and Nobel Peace Laureates.
                <br><br>
                Our capstone conferences bring internationally-renowned peace activists together with youth aged 14-25 for interactive weekend where they engage in dialogue around solutions to the most pressing issues they are facing today, and learn how to be effective change makers in their communities.
                <br><br>
                PeaceJam also hosts regional summits, trainings, and workshops both in-person and online. Check out the calendar below for upcoming events.
              </p>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import moment from "moment";
  import EventsReview from "../components/events/EventsReview";

  export default {
    name: "Events",
    data() {
      return {
        date: new Date(),
        offset: 0,
        limit: 6,
        type: 'gte'
      }
    },
    mounted() {
      window.scrollTo(0, 0);
    },
    components: {
      EventsReview,
    },
    methods: {
      async init() {
        if (this.$route.query.date) {
          this.date = this.$route.query.date;

          this.offset = this.$route.query.offset ? this.$route.query.offset : 0;

        } else {
          this.$router.path('/')
        }
      },
      prevPage() {
        if (this.offset !== 0) {
          this.offset = +this.offset - +this.limit;
          this.$router.replace({name: "events", query: {date: this.date, offset: this.offset}});
        }
      },
      nextPage() {
        this.offset = +this.offset + +this.limit;
        this.$router.replace({name: "events", query: {date: this.date, offset: this.offset}});
      },
      updateDate(val) {
        this.date = moment(val).format('YYYY-MM-DD');
        this.type = 'gte';
        this.$router.replace({ name: "events", query: {date: this.date, offset: this.offset} })
      },
      changeType(val) {
        this.type = val
      }
    },
    watch: {
      '$route.query': {
        immediate: true,
        handler() {
          if (!this.$route.query.date) {
            this.date = moment(new Date()).format('YYYY-MM-DD');
            this.$router.replace({name: "events", query: {date: this.date}});
          }
          this.init();
        }
      }
    },
  }
</script>

<style scoped lang="scss">
  .event-page-header {
    padding-top: 60px;
    padding-bottom: 60px;
    background-image: url("../assets/img/events/events-banner.jpg");
    background-size: cover;
    background-position: center left;
    margin-top: 121px;

    p {
      color: #fff;
      font-size: 17px;
      text-align: left;
    }
  }

  @media (max-width: 992px) {
    p {
      font-size: 15px;
    }
  }
</style>
